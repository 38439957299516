import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core'
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {environment} from '../../../environments/environment'
import {GenericTypesService, ICountry} from '../../application/data-types'
import {BehaviorSubject} from 'rxjs'
import {COMMA, ENTER} from '@angular/cdk/keycodes'
import {KycService} from '../../services/kyc.service'
import {MatDialog} from '@angular/material/dialog'
import {Router} from '@angular/router'
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete'
import {checkboxValidator} from '../../directives/checkbox/CheckboxValidator'
import {careOfValidator} from '../../directives/careOf/CareOfValidator'
import {filter, finalize, first} from 'rxjs/operators'
import {WaitDialogComponent} from '../../common/wait-dialog/wait-dialog.component'
import {AdminService} from '../../services/admin.service'
import {Finalize} from '../../services/finalize.interface'
import {generate} from '@sparbanken-syd/personnummer'
import {pnrValidator} from '@sparbanken-syd/sparbanken-syd-theme'


@Component({
  selector: 'spb-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  /**
   * We have to get this from the template to be able to reset its
   * value when a "chip" is selected from the auto complete. This is named
   * in the template.
   */
  @ViewChild('citizenInput', {static: true}) citizenInput: ElementRef<HTMLInputElement> = {} as any

  public debug: boolean = environment.debug

  public submitError = ''

  aboutMeForm = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    personNummer: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(13),
      pnrValidator
    ]),
    address: new FormControl<string>('', Validators.required),
    co: new FormGroup({
      coAddress: new FormControl<string>(''),
      careOf: new FormControl<string>('', Validators.required)
    }, careOfValidator),
    postalCode: new FormControl<string>('', Validators.required),
    postalArea: new FormControl<string>('', Validators.required),
    country: new FormControl<string>('Sverige', Validators.required),
    citizenOf: new FormArray([
      new FormControl<string>('Sverige')
    ], Validators.required),
    taxUSA: new FormControl<string>('', {nonNullable: true, validators: Validators.required})
  })

  myEconomyForm = new FormGroup({
    privateAccount: new FormControl<boolean>(false),
    bankCard: new FormControl<boolean>(false),
    savingsAccount: new FormControl<boolean>(false),
    fundAccount: new FormControl<boolean>(false),
    investmentAccount: new FormControl<boolean>(false),
    internetBank: new FormControl<boolean>(false)
  }, checkboxValidator)

  guardiansForm = new FormGroup({
    guardians: new FormArray([
      this.createGuardian()
    ])
  })

  depositsForm = new FormGroup({
    deposits: new FormControl<string>('', Validators.required),
    cashDeposits: new FormControl<string>('')
  })

  masterForm = new FormGroup({
    general: this.aboutMeForm,
    economy: this.myEconomyForm,
    deposits: this.depositsForm,
    guardians: this.guardiansForm,
  })

  public cashDepositCountOptions = [
    {id: 0, name: 'Inget'},
    {id: 1, name: '0 - 50 000 kr'},
    {id: 2, name: '50 001 - 100 000 kr'},
    {id: 3, name: '100 001 - 150 000 kr'},
    {id: 4, name: 'Mer än 150 000 kr'}
  ]

  public errorMessage: string = ''

  /**
   * This is a form control to hold the role chips only
   * and not part of the form.
   */
  public citizenCtrl = new FormControl()

  /**
   * Used to start a new chip in the country list.
   */
  public separatorKeysCodes: number[] = [ENTER, COMMA]

  /**
   * A shorter list of countries to display in the countries auto complete.
   */
  public filteredCountries: BehaviorSubject<ICountry[]> = new BehaviorSubject<ICountry[]>(GenericTypesService.countryList)

  public mockEnabled: boolean = environment.mock

  public finalizer: Finalize = {} as any

  constructor(private kycService: KycService,
              private submitDialog: MatDialog,
              private router: Router,
              private injector: Injector,
              public adminService: AdminService) {
  }

  ngOnInit(): void {
    this.finalizer = this.kycService
    this.adminService.adminData$.pipe(
      filter((res: any) => !!res),
      first()
    ).subscribe({
      next: () => {
        this.finalizer = this.injector.get(AdminService)
      }
    })
  }

  /**
   * A bunch of getters to make the rest of the code more readable.
   */
  get citizenOf(): FormArray {
    return this.aboutMeForm.get('citizenOf') as FormArray
  }

  get careOfSelect(): string {
    return (this.aboutMeForm.controls.co.get('careOf') as AbstractControl).value
  }

  get guardians(): FormArray {
    return this.guardiansForm.get('guardians') as FormArray
  }

  get careOf(): FormGroup {
    return this.aboutMeForm.controls.co as FormGroup
  }

  /**
   * Mocks data into the form to avoid total brain meltdown during testing
   */
  setData(): void {
    this.aboutMeForm.patchValue({
      name: 'Anders Svensson',
      personNummer: '198906291003',
      address: 'Test Stigen 12',
      postalCode: '21617',
      postalArea: 'Limhamn',
      co: {
        careOf: '2',
        coAddress: 'Stefan Löfven'
      },
      taxUSA: 'Ja'
    })

    this.myEconomyForm.patchValue({
      privateAccount: true
    })

    this.depositsForm.patchValue({
      deposits: JSON.stringify({id: 2, name: '50 001 - 100 000 kr'}),
      cashDeposits: 'Ja'
    })

    this.guardians.at(0).patchValue(environment.testData.guardians[0])

    this.guardians.push(this.createGuardian(environment.testData.guardians[1]))
  }

  submit(): void {
    // Check that the personnummer for applicant is not used as guardian
    // Or simply write a directive.
    this.submitError = ''
    this.guardians.controls.forEach((key, index) => {
      if (this.guardians.at(index).value.personNummer === (this.aboutMeForm.get('personNummer') as AbstractControl).value) {
        this.submitError = 'Du kan inte ange samma personnummer för minderårig som för målsman'
      }
    })
    if (this.submitError !== '') {
      return
    }
    const waitRef = this.submitDialog.open(WaitDialogComponent, {
      disableClose: true,
      data: {
        title: 'Registrering...',
        text: 'Vi registrerar ditt formulär, ett ögonblick...'
      }
    })

    // pass the form data to a service that shoots to BE
    const kyc: any = this.masterForm.getRawValue()

    kyc.guardians = this.guardians.value
    kyc.general.careOf = this.careOf.controls.careOf.value
    kyc.general.coAddress = this.careOf.controls.coAddress.value
    delete kyc.general.co

    this.finalizer.submitMinor(kyc).pipe(
      finalize(() => waitRef.close())
    ).subscribe({
      // om admin, routa till admin overview sidan!
      next: (res) => {
        if (this.finalizer.admin) {
          this.router.navigate(['foralder', 'admin'], {queryParams: {kycId: res.id}}).then()
        } else {
          this.router.navigate(['minderarig', 'klar']).then()
        }
      },
      error: () => this.errorMessage = 'Kunde ej slutföra registreringen!'
    })
  }

  /**
   * Needed to be able to set select programmatically
   */
  public compareDeposits(o1: any, o2: any): boolean {
    return o1.id === o2.id
  }

  addGuardian(): void {
    this.guardians.push(this.createGuardian())
  }

  removeGuardian(index: number): void {
    this.guardians.removeAt(index)
  }

  addCitizenship(event: MatAutocompleteSelectedEvent) {
    this.citizenOf.push(new FormControl(event.option.value))
    this.citizenInput.nativeElement.value = ''
    this.citizenInput.nativeElement.blur()
    this.filteredCountries.next(GenericTypesService.countryList)
  }

  removeCitizenship(index: number): void {
    this.citizenOf.removeAt(index)
  }

  /**
   * Filters the country list based on the string received.
   *
   * @param value - The string to filter on.
   */
  public filterCountries(value: string): void {
    const filtered = GenericTypesService.countryList.filter(country => {
      const val = value.toLowerCase()
      return country.name.toLowerCase().indexOf(val) !== -1
    })
    this.filteredCountries.next(filtered)
  }

  createGuardian(guardian: any = {}): FormGroup {
    const fg = new FormGroup({
      name: new FormControl<string>('', [Validators.required, Validators.minLength(3)]),
      personNummer: new FormControl<string>('',
        [Validators.required, Validators.minLength(10), Validators.maxLength(13), pnrValidator]),
      email: new FormControl<string>('', [Validators.required, Validators.email]),
      signAtOffice: new FormControl<boolean>(false)
    })
    fg.patchValue(guardian)
    return fg
  }

  clearSubmitError(): void {
    this.submitError = ''
  }

  public getRandomPersonnummer(): void {
    this.aboutMeForm.controls.personNummer.setValue(generate())
  }
}
